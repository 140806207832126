<template>
    <div class="index-main app-content">
      <swiper class="swiper app-content index-banner" :options="swiperBannerOption" ref="swiperBanner">
        <swiper-slide v-for="(item, key) in bannerList" :key = key 
        :style = "{backgroundImage: `url(${item.img})`}"></swiper-slide>
        <div class="swiper-pagination img-swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="text-banner">
        <div class="text-banner-prev"></div>
        <div class="text-banner-main">
          <swiper class="swiper" :options="swiperTextOption" ref="swiperText">
              <swiper-slide v-for="(item, key) in bannerList" :key = key>{{item.text}}</swiper-slide>
          </swiper>
        </div>
        <div class="text-banner-next"></div>
      </div>
      <footer-copy :pos="'absolute'"></footer-copy>   
    </div>
</template>
<script>
export default {
  name: "index",
  data () {
    return {
       bannerList: [
        //  { img: '//txwl.internetbaidu.com/mobileTXW_3.0/index/banner/banner3.jpg', text: '真诚邀你相约菲律宾·马尼拉2020PAGE亚博会'  },
         { img: '//txwl.internetbaidu.com/mobileTXW_3.0/index/banner/banner5.jpg', text: '业精于专，功成于勤' },
       /*   { img: '//txwl.internetbaidu.com/mobileTXW_3.0/index/banner/banner4.jpg', text: '顶级娱乐平台，尊享刺激视觉盛宴。' }, */
         { img: '//txwl.internetbaidu.com/mobileTXW_3.0/index/banner/banner2.jpg', text: '玩转棋牌，引领市场！' },
         { img: '//txwl.internetbaidu.com/mobileTXW_3.0/index/banner/banner1.jpg', text: '传承经典，趣味无限！'}
       ],
       swiperBannerOption: {
          loop: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.img-swiper-pagination',
            clickable: true
          }
        },
        swiperTextOption: {
          loop: true,
          navigation: {
            nextEl: '.text-banner-next',
            prevEl: '.text-banner-prev'
          }
        }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperBanner = this.$refs.swiperBanner.$swiper
      const swiperText = this.$refs.swiperText.$swiper
      swiperBanner.controller.control = swiperText
      swiperText.controller.control = swiperBanner
    })
  }
}
</script>
<style lang="less">
.index-main {
  position: relative;
}
.index-banner {
  .swiper-slide {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
  }
  .swiper-pagination-bullets {
    bottom: 22%;
    .swiper-pagination-bullet {
      width: 30px;
      height: 30px;
      background: rgba(255, 255, 255, 0.6);
      &.swiper-pagination-bullet-active {
        background: #B11D22;
      }
    }
  }
}
.text-banner { 
  position: absolute;
  width: 94%;
  height: 120px;
  background:linear-gradient(0deg, #262853 0%, #302E55 100%);
  opacity:0.8;
  border-radius:10px;
  left: 3%;
  bottom: 12%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    height: 100%;
  }
  .text-banner-main {
    width: calc(100% - 200px);
    font-size: 40px;
    .swiper-slide {
      line-height: 120px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
  }
  .text-banner-prev, .text-banner-next {
    width: 100px;
    height: 100%;
    background-size: 18px 36px;
    background-position: center center;
    background-repeat: no-repeat;
  }
   .text-banner-prev {
     background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/left.png');
   }
   .text-banner-next {
     background-image: url('//txwl.internetbaidu.com/mobileTXW_3.0/index/right.png');
   }
}

.smallScreen() {
    .text-banner {
      bottom: 8%;
    }
    .index-banner .swiper-pagination-bullets {
      bottom: 18%;
    }
}

// iPhone 6, 7, & 8
@media only screen and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2){ 
    .smallScreen()
}
// iphone 6+, 6s+, 7+, 8+
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)  {
    .smallScreen()
}
</style>